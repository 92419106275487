import React, { useState, useEffect, useContext } from 'react'
import ThemeContext from '../context/theme/ThemeContext';

import Car1 from '../Images/level2.jpg';
import Car2 from '../Images/car2.jpg';
import Car3 from '../Images/car3.jpeg';

const Workbook = (props) => {
    const themeContextData = useContext(ThemeContext);
    const theme = themeContextData.theme;

    useEffect(() => {
        props.setLoadingProgress(30);
        setTimeout(() => {
            props.setLoadingProgress(100);
        }, 300);
    }, [])


    return (
        <div className={`container my-4 text-${theme === "dark" ? "light" : "dark"}`}>
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Car1} className="d-block mx-auto img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={Car2} className="d-block mx-auto img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={Car3} className="d-block mx-auto img-fluid" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <h2 className='mt-4 text-center'>Self Talk Workbook Level 2</h2>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <h3 className='me-2'>&#8377;669 </h3> <strike><h5 className=''>&#8377;1499</h5></strike>
            </div>

            <div className="row">
                <div className="col-md-6 mb-4">
                    <p className='justifytext'><b>Product details:</b></p>
                    <p className='justifytext'>Paper: 100 GSM</p>
                    <p className='justifytext'>Pages: 192</p>
                    <p className='justifytext'>Binding type: Spiral (to make it writing friendly)</p>
                    <p className='justifytext'>Weight: 800 gms (approx.)</p>
                    <a
                        href="https://wa.me/+919990355372"
                        className={`btn btn-lg btn-${theme === "dark" ? "light" : "dark"} w-100`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Buy Now on WhatsApp
                    </a>
                </div>
                <div className="col-md-6">
                    <p className='justifytext'>At LEVEL 2 (classes 6 to 8), this book aims at developing a daily habit of writing positive affirmations and gratitude along with recording plans for the day and thoughts.</p>
                    <p className='justifytext'>At Trainskillz, we believe that if we sow the seed of the right habits at the very beginning of a child&#39;s life and keep nurturing it without fail, the child will develop a wholesome personality.</p>
                    <p className='justifytext'>With Self Talk, we are targeting the child&#39;s mental health and inner power which would benefit the child in the long run.</p>
                    <p className='justifytext'>In this fast-paced life, what we forget or often neglect to care about is our mental health. Stress, anxiety and depression have become household names. The root cause of half of the ailments that one suffers from is one&#39;s mental health. This problem is not just restricted to one&#39;s bad temperament or mental condition but it leads to more dangerous modifications in one&#39;s behaviour. Self-hate, frustration, guilt, low self-esteem, low confidence etc becomes so difficult to handle at times that it culminates into crimes and suicides.</p>
                    <p className='justifytext'>The sole motive behind this self-book is to make your child self-sufficient and to provide him/her with a friend who listens to him/her and stays with him/her forever. After dedicatedly using this workbook, your child will experience that this book talks back miraculously by fulfilling his/her true desires and prayers.</p>
                    <p className='justifytext'>Mind rules our body and all our activities throughout the day. This book is designed to harness your mind.</p>
                    <p className='justifytext'>We are not born with personalities, it is developed over time. Why not help ourselves to develop a great personality that is loved and revered by all?</p>
                    <p className='justifytext'>Mental health is the most neglected part of our lives, yet the utmost vital one. Let&#39;s answer this problem with a great solution. How about gifting our loved ones with such an amazing solution to all their problems of life? Once they learn using this miracle wisely and beneficially, they will be the most benefitted ones.</p>
                    <p className='justifytext'>Before gifting others, we must first gift this bundle of self-love to ourselves.</p>
                    <p className='justifytext'>This workbook comes in various levels targeting different age groups. Level 2 contains 30 days, daily positive affirmations and gratitude writing along with daily plan and journal recording sheets, per month for 3 months. After successfully completing this book, the next segment of books can be availed of for the continued benefit.</p>
                </div>
            </div>
        </div >
    )
}

export default Workbook