import React, { useState, useEffect, useContext } from 'react'
import ThemeContext from '../context/theme/ThemeContext';
import parse from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../Components/Spinner';

const Blog = (props) => {
    const themeContextData = useContext(ThemeContext);
    const theme = themeContextData.theme;

    const params = useParams();
    const slug = params.blogSlug;

    const [blogData, setBlogData] = useState({});
    const [isLoadingBlog, setIsLoadingBlog] = useState(true);

    useEffect(async () => {
        props.setLoadingProgress(30);
        // const url = `http://127.0.0.1:8000/api/blog/${slug}`;
        const url = `https://educational-backend.onrender.com/api/blog/${slug}`;
        const response = await fetch(url);
        const parsedData = await response.json();
        props.setLoadingProgress(60);
        setBlogData(parsedData);
        setIsLoadingBlog(false);
        props.setLoadingProgress(100);
    }, [])

    return (
        <div className={`container text-${theme === "dark" ? "light" : "dark"} my-4`} style={{minHeight: "70vh"}}>
            {isLoadingBlog ? <div className="text-center"><Spinner /></div> :
                <>
                    <h1>{blogData.title}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link className='text-decoration-none' to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link className='text-decoration-none' to="/blogs">Blogs</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{blogData.title}</li>
                        </ol>
                    </nav>
                    <div className='d-flex my-2'>
                        <div className="me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar4-week" viewBox="0 0 16 16">
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                                <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                            </svg>
                            <span>{new Date(blogData.publish_date).toLocaleString()}</span>
                        </div>
                        <div className="me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                            </svg>
                            <span>{blogData.views}</span>
                        </div>
                    </div>
                    {parse(String(blogData.body))}
                </>
            }
        </div>
    )
}

export default Blog