import React, { useContext } from 'react';
import ThemeContext from '../context/theme/ThemeContext';

const Spinner = () => {
    const themeContextData = useContext(ThemeContext);
    const theme = themeContextData.theme;

    return (
        <div className={`spinner-border text-${theme==="dark"?"light":"dark"}`} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}

export default Spinner