import React, { useState, useEffect, useContext } from 'react'
import ThemeContext from '../context/theme/ThemeContext';

import Car1 from '../Images/level3.jpg';
import Car2 from '../Images/car2.jpg';
import Car3 from '../Images/car3.jpeg';

const Workbook = (props) => {
    const themeContextData = useContext(ThemeContext);
    const theme = themeContextData.theme;

    useEffect(() => {
        props.setLoadingProgress(30);
        setTimeout(() => {
            props.setLoadingProgress(100);
        }, 300);
    }, [])


    return (
        <div className={`container my-4 text-${theme === "dark" ? "light" : "dark"}`}>
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Car1} className="d-block mx-auto img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={Car2} className="d-block mx-auto img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={Car3} className="d-block mx-auto img-fluid" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <h2 className='mt-4 text-center'>Self Talk Workbook Level 3</h2>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <h3 className='me-2'>&#8377;669 </h3> <strike><h5 className=''>&#8377;1499</h5></strike>
            </div>

            <div className="row">
                <div className="col-md-6 mb-4">
                    <p className='justifytext'><b>Product details:</b></p>
                    <p className='justifytext'>Paper: 100 GSM</p>
                    <p className='justifytext'>Pages: 192</p>
                    <p className='justifytext'>Binding type: Spiral (to make it writing friendly)</p>
                    <p className='justifytext'>Weight: 800 gms (approx.)</p>
                    <a
                        href="https://wa.me/+919990355372"
                        className={`btn btn-lg btn-${theme === "dark" ? "light" : "dark"} w-100`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Buy Now on WhatsApp
                    </a>
                </div>
                <div className="col-md-6">
                    <p className='justifytext'>This is a lifebook which has been conceptualised for self-development through self-talk, daily journaling, habit building, habit tracking, SWOT analysis, finding your IKIGAI and many other self realisation techniques.</p>
                    <p className='justifytext'>In this fast paced life, what we forget or often neglect to care about is our mental health. Stress, anxiety and depression have become household names. Root cause of half of the ailments that one suffers from is one's mental health. This problem is not just restricted to one's bad temperament or mental condition but it leads to more dangerous modifications in one's behaviour. Self-hate, frustration, guilt, low self-esteem, low confidence etc becomes so difficult to handle at times that it culminates into crimes and suicides.</p>
                    <p className='justifytext'>Self-talk has been conceived with an idea to promote self help and self growth. Regular reflection makes it easy to understand your daily endeavours better. Planning and organising makes one's life systematic. It facilitates a proper path, a direction that would lead to one's chosen destination.</p>
                    <p className='justifytext'>The sole motive behind this self-book is to make you self-sufficient and to provide you with a friend who listens to you and stays with you forever. After dedicatedly using this workbook, you will experience that this book talks back miraculously with all the solutions of your problems and also develops a sense of clarity about yourself and things around.</p>
                    <p className='justifytext'>Starting from eight year old to people of all age groups, everyone can include this self-talk in their lives only to experience a positive shift in all aspects of their lives. Mind rules our body and all our activities throughout the day. This book is designed to harness your mind. We are not born with personalities, it is developed over time. Why not help ourselves to develop a great personality that is loved and revered by all?</p>
                    <p className='justifytext'>Mental health is the most neglected part of our lives, yet the utmost vital one. Let's answer this problem with great solution. How about gifting our loved ones with such an amazing solution to all their problems of life? Once they learn using this miracle wisely and beneficially, they will be the most benefitted ones. Before gifting others, we must first gift this bundle of self-love to ourselves.</p>
                    <p className='justifytext'>This book contains 21 daily journal sheets per month for 3 months alongwith various other tracking and self-analysis techniques. After successfully completing this book next segment of books can be availed of for the continued benefit.</p>
                </div>
            </div>
        </div >
    )
}

export default Workbook