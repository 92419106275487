import React from 'react'
import LoadingBar from 'react-top-loading-bar'

const TopLoadingBar = (props) => {
  return (
    <LoadingBar
        color='#f11946'
        progress={props.progress}
        onLoaderFinished={() => props.setLoadingProgress(0)}
      />
  )
}

export default TopLoadingBar