import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";

import UserContext from "./UserContext";

const UserState = (props) => {

    const [authTokens, setAuthTokens] = useState(localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [user, setUser] = useState(localStorage.getItem("authTokens") ? jwt_decode(JSON.parse(localStorage.getItem("authTokens")).access) : null);
    const [loading, setLoading] = useState(true);
    let timesFetched = 0;

    const navigate = useNavigate();

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            // const tokenUrl = `http://127.0.0.1:8000/api/token/`;
            const tokenUrl = `https://educational-backend.onrender.com/api/token/`;
            const response = await fetch(tokenUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'username': e.target.username.value, 'password': e.target.password.value })
            });
            const parsedData = await response.json();
            if (response.status === 200) {
                setAuthTokens(parsedData);
                setUser(jwt_decode(parsedData.access));
                localStorage.setItem('authTokens', JSON.stringify(parsedData));
                navigate("/");
            }
            else if (response.status === 401) {
                alert("No active account found with the given credentials!");
            }
            else {
                alert("Something went wrong!");
            }
        } catch (error) {
            alert("Error detected!");
        }
    }

    const updateToken = async () => {
        const refreshToken = JSON.parse(localStorage.getItem("authTokens"))?.refresh;
        // const refreshUrl = `http://127.0.0.1:8000/api/token/refresh/`;
        const refreshUrl = `https://educational-backend.onrender.com/api/token/refresh/`;
        const response = await fetch(refreshUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'refresh': JSON.parse(localStorage.getItem("authTokens"))?.refresh })
        });
        const parsedData = await response.json();
        if (response.status === 200) {
            setAuthTokens(parsedData);
            setUser(jwt_decode(parsedData.access));
            localStorage.setItem('authTokens', JSON.stringify(parsedData));
            timesFetched = 0;
        }
        else if (response.status === 400 || response.status === 401) {
            if(timesFetched<3) {
                updateToken();
                timesFetched += 1;
            }
            logoutUser();
        }
        else {
            logoutUser();
        }
        if (loading) {
            setLoading(false);
        }
    }

    const logoutUser = () => {
        setUser(prevState => null);
        setAuthTokens(prevState => null);
        localStorage.removeItem("authTokens");
        // navigate("/login");
    }

    useEffect(() => {
        const fourMinutes = 1000 * 60 * 4;
        // change interval update time to fourMinutes after testing
        if (loading) {
            updateToken();
        }
        const interval = setInterval(() => {
            if (authTokens) {
                updateToken();
            }
        }, 10000);
        return () => {
            clearInterval(interval);
        }
    }, [authTokens, loading]);

    const contextSharedData = {
        user,
        authTokens,
        loginUser,
        logoutUser
    }
    return (
        <UserContext.Provider value={contextSharedData}>
            {loading ? null : props.children}
        </UserContext.Provider>
    );
}

export default UserState;