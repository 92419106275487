import { useState, createContext, useEffect, useLayoutEffect } from 'react';

const ThemeContext = createContext();

const ThemeState = (props) => {
    const [theme, setTheme] = useState((localStorage.getItem("theme") === "light" || localStorage.getItem("theme") === "dark")?localStorage.getItem("theme"):"light");

    useLayoutEffect(() => {
        if (localStorage.getItem("theme") === "dark") {
            document.body.style.backgroundColor = "#2e2e2e";
            // document.body.style.backgroundColor = "#9a77cf";
        }
        else {
            document.body.style.backgroundColor = "#fff";
            // document.body.style.backgroundColor = "#262254";
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("theme", theme);
        if(theme === 'light') {
            document.body.style.backgroundColor = "#fff";
            // document.body.style.backgroundColor = "#9a77cf";
        }
        else {
            document.body.style.backgroundColor = "#2e2e2e";
            // document.body.style.backgroundColor = "#262254";
        }
    }, [theme]);

    const handleChangeTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
        }
        else {
            setTheme('light');
        }
    }
    return (
        <ThemeContext.Provider value={{ theme, handleChangeTheme }}>
            {props.children}
        </ThemeContext.Provider>
    );
}

export default ThemeContext;
export { ThemeState };