import React, { useState, useEffect, useContext } from 'react';
import ThemeContext from '../context/theme/ThemeContext';
import UserContext from '../context/user/UserContext';
import jwt_decode from 'jwt-decode';

import vision from '../Svgs/vision.svg';
import aim from '../Svgs/aim.svg';
import choose from '../Svgs/choose.svg';
import about from '../Svgs/about.svg';

const Home = (props) => {
    const themeContextData = useContext(ThemeContext);
    const userContextData = useContext(UserContext);

    const theme = themeContextData.theme;

    const [authTokens, setAuthTokens] = useState(localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [user, setUser] = useState(localStorage.getItem("authTokens") ? jwt_decode(JSON.parse(localStorage.getItem("authTokens")).access) : null);

    useEffect(() => {
        const max = 50;
        const min = 30;
        const randomNumber = parseInt(Math.random() * (max - min) + min); // random num between max & min
        props.setLoadingProgress(randomNumber);
        setTimeout(() => {
            props.setLoadingProgress(100);
        }, 100);
    }, [])
    

    return (
        <>
            <div className={`text-${theme === "light" ? "dark" : "light"}`}>
                {/* <h1>Home Component. {userContextData.user && `Hello ${userContextData.user.username}`}</h1> */}
                <div className="p-5 mb-4" id="home-jb">
                    <div className="container-fluid py-5">
                        <div className="container">
                            <h1 className="display-5 text-white text-center mb-2" style={{ fontWeight: "800" }}>Welcome to TrainSkillz</h1>
                            <p className="fs-4 text-white text-center">Become Future Ready!</p>
                            <p className="fs-4 text-white text-center">A Path to Make a Better Version of Yourself</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="container mb-4">
                        <div className="row my-2">
                            <div className="col-md-8">
                                <div>
                                    <h1>About Us</h1>
                                    <p className='justifytext'>Trainskillz has been conceptualized with a view to provide and assist an individual in his/ her self-development process. Schools are there to teach and educate about Maths, Science, Language and various other subjects. But what about learning about oneself? Learning or understanding about oneself is the most important and the most neglected one!</p>
                                    <p className='justifytext'>Before pouring one litre of milk in a container, we arrange for a container with specific attributes which makes it possible to hold one liitreof milk in it. The container shouldn’t be small or with holes otherwise the milk would not fit into it or seep down in respective cases. The factwe are trying to convey here is that first, we make the container capable of containing the milk and only then we pour the milk in it. But in our case, we only focus on pouring the learning on us before making us ready to absorb those learnings.</p>
                                    <p className='justifytext'>Why most of the students or learners don’t like to study or find it disinteresting and boring? Its just because the students are not trained to go through the process of learning in an effective manner. We need to first make the container ready! In simple words, we need to first work upon ourselves and on our capacities before checking in for the learning flight.</p>
                                    <p className='justifytext'>Working upon self includes all the parameters related to one’s mind, body and soul. Basically, Trainskillz aims at teaching what’s not taught in schools. What we researched and analysed is that the most vital things that are necessary to lead a happy and smooth life is never taught to us! This is where our education system fails majorly. </p>
                                    <p className='justifytext'>We see that a student with average score in his studies or even dropouts rise up to become the most successful business owners whereas the toppers and rank holders end up working for them. What must have gone wrong here?</p>
                                    <p className='justifytext'>The subjects and course content that are taught in schools doesn’t guarantee success in future but things that you will learn at Trainskillz will definitely do that. It is not knowledge but one’s personality that puts one into limelight. A person having all the knowledge but unable to express or address an audience is equal to a person having zero knowledge. On the contrary, a person having little knowledge but great personality with good communication skills and wit to attract people’s attention is considered hero and praised.</p>
                                    <p className='justifytext'>Trainskillz is a parallel education system making you future-ready! Initially, we are focusing on mind, body and soul i.e., brain and personality development. Our aim is to make each one of you self-sufficient, smart and intelligent to stand out among others.</p>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <img src={about} className={`mx-auto d-block m-2 img-fluid`} alt="About Us" />
                            </div>
                        </div>

                        <hr />

                        <div className="row my-2">
                        <div className="col-md-6">
                            <img src={vision} className={`mx-auto d-block m-2 img-fluid`} alt="Our Vision" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h1>Our Vision</h1>
                                <p className='justifytext'>We want each individual to be trained to his/her best potential so as to be fully guided to reap the best benefits of the practical world!</p>
                            </div>
                        </div>
                        </div>

                        <hr />

                        <div className="row my-2">
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h1>Our Aim</h1>
                                <p className='justifytext'>Our aim is to train students by teaching them what is untaught in schools. Our Course provides parallel education which would train the students and make them ‘ready for the world’. They would be able to plan and design the ’life of their dreams’.</p>
                                <p className='justifytext'>Trainskillz courses will allow students to find out their passion, nurture it and ultimately convert it into their career. Who can be the best career-counsellors than the students themselves? Guiding them and making them aware about the steps which would lead them to self-realisation and clarity would make them independent and good decision makers.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={aim} className={`mx-auto d-block m-2 img-fluid`} alt="Our Vision" />
                        </div>
                        </div>

                        <hr />

                        <div className="row my-2">
                        <div className="col-md-6">
                            <img src={choose} className={`mx-auto d-block m-2 img-fluid`} alt="Our Aim" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h1>Why Choose Us?</h1>
                                <p className='justifytext'>Students need a dedicated course to be aware of the practical world and to get equipped with necessary skill-sets which would help them design ‘the life of their dreams’.</p>
                                <p className='justifytext'>Majority of the students are unaware or find it difficult to select the correct stream after class X. Requirement of a proper course which would educate them and bring clarity, is at its peak.</p>
                                <p className='justifytext'>Trainskillz is working profusely to provide you with the well-researched effective quality upskilling courses and products to enrich your learning and improve the quality of your life.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
