import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../context/user/UserContext';
import ThemeContext from '../context/theme/ThemeContext';
import { useLocation } from "react-router-dom"

const Navbar = () => {
    const location = useLocation();

    const userContextData = useContext(UserContext);

    const themeContextData = useContext(ThemeContext);
    const theme = themeContextData.theme;

    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-${theme} bg-${theme}`}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/" style={{ fontFamily: "Encode Sans", fontWeight: "600", fontSize: "28px" }}>TrainSkillz</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContents" aria-controls="navbarContents" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarContents">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} aria-current="page" to="/">Home</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/courses">Courses</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/products">Products</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/blogs" ? "active" : ""}`} to="/blogs">Blogs</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Our Self Talk Book
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/workbook/level1">Level 1</Link></li>
                                    <li><Link className="dropdown-item" to="/workbook/level2">Level 2</Link></li>
                                    <li><Link className="dropdown-item" to="/workbook/level3">Level 3</Link></li>
                                </ul>
                            </li>
                        </ul>
                        <div className="d-flex">
                            <button onClick={themeContextData.handleChangeTheme} className={`btn btn-outline-${theme === "dark" ? "light" : "dark"} me-2`}>
                                {theme === "dark" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: "3px" }} width="16" height="16" fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                                        <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: "3px" }} width="16" height="16" fill="currentColor" className="bi bi-moon-fill" viewBox="0 0 16 16">
                                        <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
                                    </svg>
                                }
                            </button>
                            {/* {userContextData.user ?
                                <div className="btn-group">
                                    <button type="button" className={`btn btn-outline-${theme === "dark" ? "light" : "dark"} dropdown-toggle`} data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                        </svg>
                                    </button>
                                    <ul className={`dropdown-menu dropdown-menu-lg-end dropdown-menu-md-start ${theme === "dark" ? "dropdown-menu-dark" : ""}`}>
                                        <li><button className="dropdown-item" type="button">My Courses</button></li>
                                        <li><button className="dropdown-item" type="button">Account Settings</button></li>
                                        <li><button className="dropdown-item" type="button">Refer &amp; Earn</button></li>
                                        <li><button className="dropdown-item" onClick={userContextData.logoutUser} type="button">Logout</button></li>
                                    </ul>
                                </div> :
                                <>
                                    <Link className={`btn btn-outline-${theme === "dark" ? "light" : "dark"} me-2`} to="/login">Login</Link>
                                    <Link className={`btn btn-outline-${theme === "dark" ? "light" : "dark"}`} to="/signup">Signup</Link>
                                </>
                            } */}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
