import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Pages
import Home from './Pages/Home';
// import Signup from './Pages/Signup';
// import Login from './Pages/Login';
// import AllCourses from './Pages/AllCourses';
// import Course from './Pages/Course';
// import Lecture from './Pages/Lecture';
// import Products from './Pages/Products';
// import Product from './Pages/Product';
// import Checkout from './Pages/Checkout';
import Error404 from './Pages/Error404';
import AllBlogs from './Pages/AllBlogs';
import Blog from './Pages/Blog';
import Workbook1 from './Pages/WorkbookLevel1';
import Workbook2 from './Pages/WorkbookLevel2';
import Workbook3 from './Pages/WorkbookLevel3';

// Components
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import TopLoadingBar from './Components/TopLoadingBar';

// Contexts
import UserState from './context/user/UserState';
import { ThemeState } from './context/theme/ThemeContext';

import {useState} from 'react';

const App = () => {
  const [progress, setProgress] = useState(0);

  const handleProgressChange = (value) => {
    setProgress(value);
  }

  return (
    <>
      <BrowserRouter>
        <UserState>
          <ThemeState>
            <TopLoadingBar progress={progress} setLoadingProgress={handleProgressChange} />
            <Navbar />
            <Routes>
            {/* Todo: Add loading progress in each route and spinners wherever necessary */}
              <Route index path="/" element={<Home setLoadingProgress={handleProgressChange} />} />
              <Route path="/blogs" element={<AllBlogs setLoadingProgress={handleProgressChange} />} />
              <Route path="/blog/:blogSlug" element={<Blog setLoadingProgress={handleProgressChange} />} />
              {/* <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} /> 
              <Route path="/courses" element={<AllCourses />} />
              <Route path="/course/:courseSlug" element={<Course />} />
              <Route path="/lecture/:lectureSlug" element={<Lecture />} />
              <Route path="/products" element={<Products />} />
              <Route path="/product/:productSlug" element={<Product />} />
            <Route path="/checkout/:type/:productSlug" element={<Checkout />} /> */}
              <Route path="/workbook/level1" element={<Workbook1 setLoadingProgress={handleProgressChange} />} />
              <Route path="/workbook/level2" element={<Workbook2 setLoadingProgress={handleProgressChange} />} />
              <Route path="/workbook/level3" element={<Workbook3 setLoadingProgress={handleProgressChange} />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
            <Footer />
          </ThemeState>
        </UserState>
      </BrowserRouter>
    </>
  );
}

export default App;
