import React, { useContext } from 'react'
import ThemeContext from '../context/theme/ThemeContext';
import error404 from '../Svgs/error.svg';


const Error404 = () => {
    const themeContextData = useContext(ThemeContext);
    const theme = themeContextData.theme;

    return (
        <div className={`container my-4 text-${theme === "dark" ? "light" : "dark"}`}>
            <h1 className="display-3 text-center">Oops!</h1>
            <h1 className="display-6 text-center">Looks like you have got lost 👀</h1>
            <p className="lead text-center">The page you are looking for is no longer here, or never existed in the first place.</p>
            <img src={error404} className={`mx-auto d-block m-2 img-fluid`} alt="Error 404" />
        </div>
    )
}

export default Error404