import React, { useState, useEffect, useContext } from 'react'
import BlogComponent from '../Components/BlogComponent';
import Spinner from '../Components/Spinner';
import ThemeContext from '../context/theme/ThemeContext';
import InfiniteScroll from "react-infinite-scroll-component";

const Blogs = (props) => {
    const themeContextData = useContext(ThemeContext);
    const theme = themeContextData.theme;

    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(0);
    const [maxPages, setmaxPages] = useState(0);
    const [isLoadingBlogs, setIsLoadingBlogs] = useState(true)

    useEffect(() => {
        fetchMoreData();
    }, [])

    const fetchMoreData = async () => {
        // const url = `http://localhost:8000/api/blogs/${page+1}`;
        // https://educational-backend.onrender.com/api/
        props.setLoadingProgress(30);
        const url = `https://educational-backend.onrender.com/api/blogs/${page+1}`;
        const response = await fetch(url);
        const parsedData = await response.json();
        props.setLoadingProgress(70);
        setBlogs(blogs.concat(parsedData.blogs));
        setPage(prevState => prevState+1);
        setmaxPages(parsedData.totalNumberOfPages);
        setIsLoadingBlogs(false);
        props.setLoadingProgress(100);
    }

    return (
        <div className={`container text-${theme === "dark" ? "light" : "dark"}`} style={{minHeight: "70vh"}}>
            <h1 className='my-4 text-center'>Read Latest Blogs</h1>
            {
                isLoadingBlogs ? <div className="text-center"><Spinner /></div> :
                <InfiniteScroll
                    dataLength={blogs.length}
                    next={fetchMoreData}
                    hasMore={page<maxPages}
                    loader={<div className="text-center my-4"> <Spinner /> </div>}
                    endMessage={
                        <>
                            <h5 className={`text-center mb-2 mt-4`}>That's all the blogs we have for now!</h5>
                            <h5 className={`text-center mt-2 mb-4`}>Thank you for being such an enthusiastic reader of our blogs.</h5>
                        </>
                    }
                >
                    {
                        blogs.map((blog, index) => {
                            return <BlogComponent key={index} theme={theme} blog={blog} />
                        })
                    }
                </InfiniteScroll>
            }
        </div>
    )
}

export default Blogs